<template>
    <div class="products_wrapper">
        <div class="bread-item d-flex flex-column justify-content-center align-items-start px-8">
            <nav aria-label="breadcrumb" class="breadcrumb-wrap">
                <h3>{{ $t('Products') }}</h3>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <router-link to="/">{{ $t('Home') }}</router-link>
                    </li>
                    <li class="breadcrumb-item">
                        <router-link to="/products">{{ $t('Products') }}</router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        potok
                    </li>
                </ol>
            </nav>
            <v-img class="image" src="@/assets/images/tebaba/bread.png" max-width="500px"></v-img>
        </div>
        <div class="content my-12">
            <div class="container">
                <v-row align="center" justify="center">
                    <v-col cols="12" class="banner_wrapper">
                        <h2>{{ $t('Video') }}</h2>
                    </v-col>
                    <v-col cols="12" class="text-center">
                        <p class="mt-6">{{$t('ProductsDetails.potok.par1')}}</p>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <p>{{$t('ProductsDetails.potok.par2')}}</p>
                    </v-col>
                    <v-col cols="12">
                        <div class="d-flex">
                            <v-img src="@/assets/images/tebaba/pat.png" max-width="250px" class="mx-6" contain></v-img>
                            <v-img src="@/assets/images/tebaba/trust.png" max-width="250px" contain></v-img>
                        </div>
                    </v-col>
                </v-row>
                <v-row align="center" justify="start">
                    <v-col cols="12" lg="4" md="4" sm="6" v-for="product in products" :key="product.id">
                        <div class="product d-flex flex-column align-center justify-center">
                            <v-img :src="require('@/assets/images/tebaba/' + product.src)" contain max-width="200px"
                                max-height="200px" />
                            <div class="spacer-w">
                                <h6 class="mt-4 px-4 text-center" @click="openDetails(product)"
                                    v-if="product.details !== false">
                                    {{ product.name }}
                                </h6>
                                <h6 class="mt-4 px-4 text-center" v-else>
                                    {{ product.name }}
                                </h6>

                                <v-btn color="#30B5EC" class="mt-2 btn-down" v-if="product.download !== false">{{
                                $t('Btns.download') }} <v-icon small class="mx-2">{{ icons.download }}</v-icon>
                                </v-btn>
                            </div>

                        </div>
                    </v-col>
                </v-row>
                <v-row align="center" justify="center" class="mt-16">
                    <v-col cols="12" class="text-center">
                        <p class="mb-0">{{$t('Headings.cureair')}}</p>
                        <SendMessage></SendMessage>
                    </v-col>
                </v-row>
            </div>
        </div>
    </div>
</template>
<script>
import SendMessage from '@/components/sendMessage.vue';
export default {
    data() {
        return {
            icons: {
                download: "fal fa-download",
            },
            products: [
                {
                    id: "SADRA",
                    name: "Standalone Air Decontamination Recycling Appliances",
                    src: "pd7.png",
                    download: false,
                    details: false,
                },
                {
                    id: "IADU",
                    name: "In-duct Air Decontamination Units",
                    src: "pd6.png",
                    download: false,
                    details: false,
                },
                {
                    id: "LFADU",
                    name: "Laminar Flow air Decontamination Units",
                    src: "pd5.png",
                    download: false,
                    details: false,
                },
                {
                    id: "STU",
                    name: "Ceilingmounted air distribution system",
                    src: "pd3.png",
                    download: false,
                    details: false,
                },
                {
                    id: "STUFI",
                    name: "Standalone unit for food industry",
                    src: "pd1.png",
                    download: false,
                    details: false,
                },
                {
                    id: "CADS",
                    name: "Standalone unit",
                    src: "pd2.png",
                    download: false,
                    details: false,
                },
                {
                    id: "IMDSC",
                    name: "Induct mount devices in single outward case",
                    src: "pd4.png",
                    download: false,
                    details: false,
                },
            ],
        };
    },
    methods: {
        openDetails(product) {
            this.$router.push(`/products/${this.$route.params.id}/${product.id}`);
            // sessionStorage.setItem("product", JSON.stringify(product));
        },
    },
    components: { SendMessage }
}
</script>
  
<style lang="scss" scoped>
.products_wrapper {
    .content {
        .product {
            position: relative;
            min-height: 400px;
            padding: 10px 0;
            border: 0.5px solid #A7A5A5;
            border-radius: 10px;
            box-shadow: 0 0 12px #D8D9D6;

            .spacer-w {
                position: absolute;
                bottom: 0;
                width: 80%;
                min-height: 60px;
                border-top: 1px solid #A7A5A5;
            }

            h6 {
                cursor: pointer;
                color: #30b5ec;
            }

            .btn-down {
                width: 90%;
                margin: 0 auto;
            }
        }
    }

    .banner_wrapper {
        color: #fff;
        height: 300px;
        background-color: #a6dd49;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>
  