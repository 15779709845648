<template>
  <div class="container-fluid auth-wrap text-center"></div>
</template>

<script>
export default {
  data() {
    return {
      user: {
        email: null,
        password: null,
      },
      pending: false,
      tab: null,
      items: ['Sign in', 'Sign up'],
      show1: false,
      show2: false,
      icons: {
        facebook: '',
        google: '../assets/images/google.png',
        close: 'far fa-times',
      },
    }
  },
  methods: {
    Login() {
      this.pending = true
      const user = {
        email: 'admin@admin.com',
        password: '123456',
      }
      if ((user.email = this.user.email && user.password == this.user.password)) {
        this.pending = false
        localStorage.setItem('access_token', 'token')
        localStorage.setItem('content', true)
        this.$router.push('/')
      } else {
        this.pending = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.auth-wrap {
  min-height: 100vh;
  .left-side {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      color: #fff;
      font-size: calc(1vw + 1vh);
      font-weight: bold;
      line-height: 5vh;
      span {
        font-size: calc(2vw + 2vh);
        font-weight: bolder;
      }
    }
    img {
      width: 75%;
    }
  }
  .right-side {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #e0b1e5;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    .v-sheet.v-card {
      box-shadow: none !important;
      width: 80%;
      margin: calc(1vw + 1vh) auto;
      background-color: transparent !important;
      p {
        margin-bottom: 0 !important;
        color: #707070;
        &.network {
          color: #602167;
          font-size: 0.85rem;
          margin-bottom: 0.5rem !important;
        }
      }
      .v-tabs > .v-tabs-bar {
        background-color: transparent !important;
      }
      .v-tabs-items {
        background-color: transparent !important;
      }
      .form__wrapper {
        max-height: 400px;
        overflow-y: scroll;
        overflow-x: hidden;
        padding: 10px;
      }
    }
    .v-text-field__details {
      display: none !important;
    }
    .v-tab {
      color: #602167;
      font-weight: bold;
      text-transform: none;
    }
    .v-tabs-slider-wrapper {
      height: 3px !important;
      background-color: #602167;
      color: #602167;
      border-radius: 5px;
    }
    .v-slide-group__content {
      justify-content: center;
    }
    a,
    h6 {
      color: #602167;
    }
    h6 {
      position: relative;
      &:before {
        position: absolute;
        top: 50%;
        left: -3%;
        content: '';
        width: 50%;
        background: #707070;
        height: 0.5px;
      }
      &:after {
        position: absolute;
        top: 50%;
        right: -3%;
        content: '';
        width: 50%;
        background: #707070;
        height: 0.5px;
      }
    }
    .icon-bg {
      width: 50px;
      height: 50px;
      background: #fff;
      border-radius: 50%;
      box-shadow: 0px 2px 2px #70707071;
      line-height: 50px;
      img {
        width: 35px;
        height: 35px;
      }
      &:first-child {
        img {
          width: 25px;
          height: 25px;
        }
      }
    }
    .v-btn {
      width: 50%;
      height: 45px;
    }
  }
}
</style>
