<template>
  <div class="contact">
    <div class="back-top">
      <div class="bread-item d-flex flex-column justify-content-center align-items-start px-8">
        <nav aria-label="breadcrumb" class="breadcrumb-wrap">
          <h3>{{ $t('Contact') }}</h3>
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <router-link to="/">{{ $t('Home') }}</router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              {{ $t('Contact') }}
            </li>
          </ol>
        </nav>
        <v-img class="image" src="@/assets/images/tebaba/bread.png" max-width="500px"></v-img>
      </div>
    </div>
    <v-container class="form-wrapper">
      <v-row align="center" justify="center" class="">
        <v-col class="form-wrap" cols="11" lg="10" md="11">
          <div class="form-ww pa-12">
            <v-row justify="space-around">
              <!-- <h2>Get in touch !</h2>
              <p>Leave us a message, we will contact you as soon as possible</p> -->
              <v-col cols="12" lg="8" md="8" sm="12">
                <v-row>
                  <v-col cols="12" lg="6" md="6" sm="12">
                    <v-text-field :class="{ 'is-invalid': validateStatus($v.contact.name), 'mt-1': true }"
                      v-model="$v.contact.name.$model" :placeholder="$t('Forms.name')" outlined hide-details>
                    </v-text-field>
                    <div v-if="!$v.contact.name.required" class="invalid-feedback">{{ $t('Forms.required') }}.</div>
                  </v-col>
                  <v-col cols="12" lg="6" md="6" sm="12">
                    <v-text-field :class="{ 'is-invalid': validateStatus($v.contact.email), 'mt-1': true }"
                      v-model="$v.contact.email.$model" :placeholder="$t('Forms.email')" outlined hide-details>
                    </v-text-field>
                    <div v-if="!$v.contact.email.required" class="invalid-feedback">{{ $t('Forms.required') }}.</div>
                    <div v-if="!$v.contact.email.email" class="invalid-feedback">{{ $t('Forms.email_req') }}.</div>
                  </v-col>
                  <v-col cols="12" lg="6" md="6" sm="12">
                    <v-text-field :class="{ 'is-invalid': validateStatus($v.contact.mobile), 'mt-1': true }"
                      v-model="$v.contact.mobile.$model" :placeholder="$t('Forms.mobile')" outlined hide-details>
                    </v-text-field>
                    <div v-if="!$v.contact.mobile.required" class="invalid-feedback">{{ $t('Forms.required') }}.</div>
                  </v-col>
                  <v-col cols="12" lg="6" md="6" sm="12">
                    <v-text-field :class="{ 'is-invalid': validateStatus($v.contact.company), 'mt-1': true }"
                      v-model="$v.contact.company.$model" :placeholder="$t('Forms.company')" outlined hide-details>
                    </v-text-field>
                    <div v-if="!$v.contact.company.required" class="invalid-feedback">{{ $t('Forms.required') }}.</div>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea :class="{ 'is-invalid': validateStatus($v.contact.message), 'mt-3': true }"
                      v-model="contact.message" rows="6" :placeholder="$t('Forms.message')" no-resize outlined
                      hide-details>
                    </v-textarea>
                    <div v-if="!$v.contact.message.required" class="invalid-feedback">{{ $t('Forms.required') }}.</div>
                  </v-col>
                </v-row>

                <v-btn color="#33A4D5" class="mt-3" @click="sendMessage"> {{ $t('Btns.send') }} </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" class="">
        <v-col cols="12" lg="4" md="4" sm="6" class="info-wrapp">
          <div class="icon">
            <v-icon color="white" large>{{ icons.mail }}</v-icon>
          </div>
          <h6>
            {{ $t('Forms.email') }} <br />
            info@tebaba.me
          </h6>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="6" class="info-wrapp">
          <div class="icon">
            <v-icon color="white" large>{{ icons.phone }}</v-icon>
          </div>
          <h6>
            {{ $t('Forms.mobile') }} <br />
            0597087201 - 0540232968
          </h6>
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="6" class="info-wrapp">
          <div class="icon">
            <v-icon color="white" large>{{ icons.address }}</v-icon>
          </div>
          <h6>
            {{ $t('Forms.address') }} <br />
            المملكة العربية السعودية الرياض شارع العليا مقابل ايوا اكسبريس - العليا مكتب 305 الاحدثيات
          </h6>
        </v-col>
      </v-row>

      <Subs class="mt-12"></Subs>
    </v-container>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import Subs from '@/components/Subs.vue'

export default {
  components: { Subs },
  data() {
    return {
      icons: {
        arrow: 'fal fa-long-arrow-right',
        mail: 'fas fa-envelope',
        phone: 'fas fa-mobile',
        address: 'fas fa-map-marker-alt',
      },
      contact: {
        name: null,
        email: null,
        mobile: null,
        company: null,
        message: null,
      },
    }
  },
  methods: {
    validateStatus(validation) {
      return typeof validation != 'undefined' ? validation.$error : false
    },
    sendMessage() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        console.log('Done !');
      }
    }
  },
  validations: {
    contact: {
      name: { required },
      company: { required },
      email: { required, email },
      mobile: { required },
      message: { required },
    },
  },
}
</script>

<style lang="scss" scoped>
.bread-item {
  height: 300px;
}

.contact {
  // min-height: 1600px;

  .v-divider {
    width: 2px !important;
    max-width: 2px !important;
    border-color: rgba($color: #33a4d5, $alpha: 1) !important;
  }

  .back-top {
    position: relative;
    width: 100%;
    z-index: 0;
  }

  .info-wrap {
    border-left: 1px solid #33a4d5;
  }

  .form-wrapper {
    // position: absolute;
    // width: 100%;
    // min-height: 800px;
    // top: 17%;
    // left: 50%;
    // transform: translateX(-50%);
    z-index: 9;

    .form-wrap {
      position: relative;
      min-height: 600px;
      // min-height: 100vh;
      z-index: 99;

      // background-color: blue;
      .form-ww {
        // position: absolute;
        // top: 17%;
        // left: 50%;
        // transform: translateX(-50%);
        background-color: #fff;
        border-radius: 20px;
        box-shadow: 0 0 10px rgba($color: #33a4d5, $alpha: 0.38);
        // position: absolute;
        // width: 100%;
        // height: 100%;
        // top: -10%;
        // left: 0;

        p {
          color: #707070;
        }

        h2 {
          font-size: 2.9vw;
          font-weight: bold;
          color: #33a4d5;
        }

        .v-btn {
          // max-width: 150px;
          width: 100%;
          height: 40px;
          padding: 10px 30px;
        }

        .form-control {
          // box-shadow: 3px 3px 6px rgba($color: #000000, $alpha: 0.16) inset;
        }
      }
    }

    .info-wrapp {
      text-align: center;

      h6 {
        line-height: 25px;
        color: #707070;
      }

      .icon {
        width: 100px;
        height: 100px;
        margin: 20px auto;
        border-radius: 88px 0 89px 88px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #a6dd49;
      }

      &:nth-child(2) {
        .icon {
          background-color: #30b5ec;
        }
      }

      &:nth-child(3) {
        .icon {
          background-color: #ffbc00;
        }
      }
    }
  }

  .subs-cont {
    background: rgba($color: #f7f7f7, $alpha: 0.7);
  }
}

.footer {
  @media screen and (max-width: 768px) {
    margin-top: 150px !important;
  }
}
</style>
