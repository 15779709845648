<template>
  <div class="solutions-wrap pb-12">
    <div
      class="bread-item d-flex flex-column justify-content-center align-items-start px-8"
    >
      <nav aria-label="breadcrumb" class="breadcrumb-wrap">
        <h3>Solutions</h3>
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/">Home</router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">Solutions</li>
        </ol>
      </nav>
      <v-img class="image" src="@/assets/images/tebaba/bread.png" max-width="500px"></v-img>
    </div>
    <v-container class="solutions text-center">
      <h4>Odoo Business <span>Solutions</span></h4>
      <v-row justify="center" align="center" class="solutions-wrap__items">
        <v-col class="item__wrap" cols="12" xl="4" lg="4" md="6" sm="6">
          <div class="item rounded pa-8">
            <div class="icon d-flex justify-center align-center mb-4">
              <v-icon color="#A32AFF" large>{{ icons.hand }}</v-icon>
            </div>
            <h3>Enterprise Resource Planning</h3>
            <p>
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters, as opposed to using 'Content here,
              content here', making it look like readable English.
            </p>
          </div>
        </v-col>
        <v-col class="item__wrap" cols="12" xl="4" lg="4" md="6" sm="6">
          <div class="item rounded pa-8">
            <div class="icon d-flex justify-center align-center mb-4">
              <v-icon color="#0AD669" large>{{ icons.hand }}</v-icon>
            </div>
            <h3>Finance and Accounting Module</h3>
            <p>
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters, as opposed to using 'Content here,
              content here', making it look like readable English.
            </p>
          </div>
        </v-col>
        <v-col class="item__wrap" cols="12" xl="4" lg="4" md="6" sm="6">
          <div class="item rounded pa-8">
            <div class="icon d-flex justify-center align-center mb-4">
              <v-icon color="#2AEAFF" large>{{ icons.hand }}</v-icon>
            </div>
            <h3>Human Resources System</h3>
            <p>
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters, as opposed to using 'Content here,
              content here', making it look like readable English.
            </p>
          </div>
        </v-col>
        <v-col class="item__wrap" cols="12" xl="4" lg="4" md="6" sm="6">
          <div class="item rounded pa-8">
            <div class="icon d-flex justify-center align-center mb-4">
              <v-icon color="#FE00C2" large>{{ icons.hand }}</v-icon>
            </div>
            <h3>E-Commerce Integration</h3>
            <p>
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters, as opposed to using 'Content here,
              content here', making it look like readable English.
            </p>
          </div>
        </v-col>
        <v-col class="item__wrap" cols="12" xl="4" lg="4" md="6" sm="6">
          <div class="item rounded pa-8">
            <div class="icon d-flex justify-center align-center mb-4">
              <v-icon color="#33A4D5" large>{{ icons.hand }}</v-icon>
            </div>
            <h3>CRM</h3>
            <p>
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters, as opposed to using 'Content here,
              content here', making it look like readable English.
            </p>
          </div>
        </v-col>
        <v-col class="item__wrap" cols="12" xl="4" lg="4" md="6" sm="6">
          <div class="item rounded pa-8">
            <div class="icon d-flex justify-center align-center mb-4">
              <v-icon color="#FECB00" large>{{ icons.hand }}</v-icon>
            </div>
            <h3>E-Invoice System</h3>
            <p>
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters, as opposed to using 'Content here,
              content here', making it look like readable English.
            </p>
          </div>
        </v-col>
      </v-row>
      <div>
        <div class="btn-signup">
          <v-btn color="#33A4D5" to="/auth" class="py-7 px-12"
            >Request Program</v-btn
          >
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      icons: {
        hand: "fas fa-hands-helping",
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.solutions-wrap {
  .solutions {
    margin-top: 75px;
    .solutions-wrap__items {
      margin-top: 70px;
    }
    h4 {
      color: #000;
      font-size: 1.9vw;
      font-weight: bold;
      position: relative;
      span {
        font-weight: lighter;
        color: #33a4d5;
      }
      &:before {
        content: "";
        width: 110px;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%);
        border-bottom: 8px solid #33a4d5;
      }
    }
    .item__wrap {
      .item {
        min-height: 450px;
        // text-align: start;
        box-shadow: 0 0 6px #33a4d5;

        h3 {
          font-size: 1.5625vw;
          font-weight: bold;
        }
        p {
          color: #707070;
          margin-top: 30px;
          font-size: 1.041vw;
        }
        .icon {
          width: 100px;
          height: 100px;
          margin: 0 auto;
          background-color: rgba($color: #a32aff, $alpha: 0.3);
          border-radius: 50%;
        }
      }

      &:nth-child(2) {
        .item {
          box-shadow: 0 0 6px #33a4d5;
          //   background-color: #33a4d5;
          //   color: #fff;
          p {
            // color: #fff;
          }
          .icon {
            background-color: rgba($color: #0ad669, $alpha: 0.3);
          }
        }
      }

      &:nth-child(5) {
        .item {
          .icon {
            background-color: rgba($color: #33a4d5, $alpha: 0.3);
          }
        }
      }
      &:nth-child(3) {
        .item {
          .icon {
            background-color: rgba($color: #2aeaff, $alpha: 0.3);
          }
        }
      }
      &:nth-child(4) {
        .item {
          .icon {
            background-color: rgba($color: #fe00c2, $alpha: 0.3);
          }
        }
      }
      &:nth-child(6) {
        .item {
          .icon {
            background-color: rgba($color: #fecb00, $alpha: 0.3);
          }
        }
      }
    }
    .btn-signup {
      margin-top: 100px;
      .v-btn {
        box-shadow: 0 20px 10px rgba($color: #33a4d5, $alpha: 0.26) !important;
        font-weight: bold;
      }
    }
  }
}
</style>
