<template>
  <div class="about">
    <div class="content">
      <div class="bread-item d-flex flex-column justify-content-center align-items-start px-8">
        <nav aria-label="breadcrumb" class="breadcrumb-wrap">
          <h3>{{ $t('Products') }}</h3>
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <router-link to="/">{{ $t('Home') }}</router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">{{ $t('Products') }}</li>
          </ol>
        </nav>
        <v-img class="image" src="@/assets/images/tebaba/bread.png" max-width="500px"></v-img>
      </div>
      <div class="business-wrap container py-12 mb-6">
        <v-row justify="center" align="center" class="business">
          <v-col class="item__wrap text-center" cols="12" xl="8" lg="8" md="12" sm="12">
            <h3>{{ $t('Headings.products') }}</h3>
            <v-row align="center" justify="center">
              <v-col
                cols="12"
                lg="4"
                md="4"
                sm="6"
                v-for="company in companies"
                :key="company.id"
                @click="openDetails(company)"
                class=" mt-3"
              >
                <div class="product_item">
                  <img :src="require('@/assets/images/tebaba/' + company.src)" class="product-img" />
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <Subs class="mt-16"></Subs>
      </div>
    </div>
  </div>
</template>
<script>
// import Solutions from "@/components/Solutions.vue";
import Subs from '@/components/Subs.vue'

export default {
  data() {
    return {
      icons: {
        coin: 'fal fa-coins',
        bag: 'fal fa-briefcase',
        check: 'fal fa-badge-check',
        dollar: 'fal fa-comment-alt-dollar',
        car: 'fal fa-truck',
        return: 'fal fa-briefcase-medical',
        team: 'fal fa-users',
      },
      companies: [
        {
          id: 'bedfont',
          src: 'p3.png',
          products: [
            {
              id: 'Gastrolyzer',
              name: 'Gastrolyzer',
              src: 'pr1.png',
              download: true,
              details: false,
            },

            {
              id: 'MediGasCheck',
              name: 'Medi-Gas Check',
              src: 'pr2.png',
              download: true,
              details: false,
            },
            {
              id: 'NObreath',
              name: 'NObreath',
              src: 'pr3.png',
              download: true,
              details: false,
            },
            {
              id: 'Smokerlyzer',
              name: 'Smokerlyzer',
              src: 'pr4.png',
              download: true,
              details: false,
            },
            {
              id: 'ToxCO',
              name: 'ToxCO',
              src: 'pr5.png',
              download: true,
              details: false,
            },
          ],
        },
        {
          id: 'safer',
          src: 'p2.png',
          products: [
            {
              id: 'Safer',
              name: 'Safer',
              src: 'desc.png',
              download: false,
              details: true,
            },
          ],
        },
        {
          id: 'potok',
          src: 'p1.png',
          products: [
            {
              id: 'Gastrolyzer',
              name: 'وحدات إزالة تلوث الهواء بالتدفق الرقائقي',
              src: 'pd7.png',
              download: false,
              details: false,
            },
            {
              id: 'MediGasCheck',
              name: 'وحدات تطهير الهواء داخل القناة',
              src: 'pd6.png',
              download: false,
              details: false,
            },
            {
              id: 'NObreath',
              name: 'أجهزة إعادة تدوير إزالة تلوث الهواء المستقلة',
              src: 'pd5.png',
              download: false,
              details: false,
            },
            {
              id: 'Smokerlyzer',
              name: 'نظام توزيع الهواء المثبت على السقف',
              src: 'pd3.png',
              download: false,
              details: false,
            },
            {
              id: 'Smokerlyzer',
              name: 'وحدة مستقلة للصناعات الغذائية',
              src: 'pd1.png',
              download: false,
              details: false,
            },
            {
              id: 'APUID',
              name: 'وحدة مستقلة',
              src: 'pd2.png',
              download: false,
              details: false,
            },
            {
              id: 'LFADU',
              name: 'تحريض أجهزة التثبيت في حالة خارجية واحدة',
              src: 'pd4.png',
              download: false,
              details: false,
            },
          ],
        },
      ],
    }
  },
  methods: {
    openDetails(company) {
      this.$router.push(`/products/${company.id}`)
      sessionStorage.setItem('products', JSON.stringify(company.products))
    },
  },
  components: { Subs },
}
</script>

<style lang="scss" scoped>
.about {
  position: relative;
  .solutions__wrapper {
    margin-top: 60px;
  }
  .solutions-wrap {
    .solutions {
      .solutions__wrapper {
        margin-top: 60px !important;
      }
    }
  }
  .business-wrap {
    .product-img {
      max-width: 150px;
      cursor: pointer;
    }
    .item__wrap {
      .product_item {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 200px;
        border: 2px solid #30b5ec;
        border-radius: 15px;
        &:hover {
          background-color: rgba($color: #30b5ec, $alpha: 0.3);
          cursor: pointer;
        }
      }
    }
  }
}
</style>
