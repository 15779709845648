<template>
    <div class="contact">
        <v-container class="form-wrapper text-center">
            <p class="mb-0">{{$t('Headings.contact')}}</p>
            <v-row align="center" justify="center" class="mt-2">
                <v-col class="form-wrap" cols="11" lg="10" md="11">
                    <div class="form-ww pa-12">
                        <v-row justify="space-around">
                            <!-- <h2>Get in touch !</h2>
                <p>Leave us a message, we will contact you as soon as possible</p> -->
                            <v-col cols="12" lg="7" md="8" sm="12">
                                <v-row>
                                    <v-col cols="12" lg="6" md="6" sm="12">
                                        <v-text-field class="mt-3" outlined hide-details dense
                                            :placeholder="$t('Forms.name')" />
                                    </v-col>
                                    <v-col cols="12" lg="6" md="6" sm="12">
                                        <v-text-field class="mt-3" outlined hide-details dense
                                            :placeholder="$t('Forms.mobile')" />
                                    </v-col>
                                    <v-col cols="12">
                                        <v-textarea class="form-control-d form-control mt-3 pa-3"
                                            id="exampleFormControlTextarea1" rows="6" :placeholder="$t('Forms.message')"
                                            no-resize></v-textarea>
                                    </v-col>
                                </v-row>

                                <div class="d-flex align-center mt-3">
                                    <v-btn color="#33A4D5" class=""> {{ $t('Btns.send') }} </v-btn>
                                    <div class="file-input">
                                        <input type="file" name="file-input" id="file-input"
                                            class="file-input__input" />
                                        <label class="file-input__label" for="file-input">
                                            <v-icon small>{{icons.attach}}</v-icon>
                                            <span> {{$t('Btns.attach')}}</span>
                                        </label>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>

        </v-container>
    </div>
</template>
  
<script>

export default {
    data() {
        return {
            icons: {
                arrow: 'fal fa-long-arrow-right',
                mail: 'fas fa-envelope',
                phone: 'fas fa-mobile',
                address: 'fas fa-map-marker-alt',
                attach: 'far fa-paperclip',
            },
        }
    },
}
</script>
  
<style lang="scss" scoped>
.bread-item {
    height: 300px;
}

.file-input__input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.file-input__label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    font-weight: 400;
    color: #707070;
    font-size: 13px;
    margin: 0 10px;
    // padding: 10px 12px;
    // box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}

.contact {
    // min-height: 1600px;

    .v-divider {
        width: 2px !important;
        max-width: 2px !important;
        border-color: rgba($color: #33a4d5, $alpha: 1) !important;
    }

    .back-top {
        position: relative;
        width: 100%;
        z-index: 0;
    }

    .info-wrap {
        border-left: 1px solid #33a4d5;
    }

    .form-wrapper {
        // position: absolute;
        // width: 100%;
        // min-height: 800px;
        // top: 17%;
        // left: 50%;
        // transform: translateX(-50%);
        z-index: 9;

        .form-wrap {
            position: relative;
            min-height: 600px;
            // min-height: 100vh;
            z-index: 99;

            // background-color: blue;
            .form-ww {
                // position: absolute;
                // top: 17%;
                // left: 50%;
                // transform: translateX(-50%);
                background-color: #fff;
                border-radius: 10px;
                box-shadow: 0 0 30px rgba($color: #707070, $alpha: 0.1);
                // position: absolute;
                // width: 100%;
                // height: 100%;
                // top: -10%;
                // left: 0;

                p {
                    color: #707070;
                }

                h2 {
                    // font-size: 2.9vw;
                    font-weight: bold;
                    color: #33a4d5;
                }

                .v-btn {
                    // max-width: 150px;
                    // width: 100%;
                    height: 40px;
                    padding: 10px 30px;
                }

                .form-control {
                    // box-shadow: 3px 3px 6px rgba($color: #000000, $alpha: 0.16) inset;
                }
            }
        }
    }
}

.footer {
    @media screen and (max-width: 768px) {
        margin-top: 150px !important;
    }
}
</style>
  