<template>
  <div class="products_wrapper">
    <div class="bread-item d-flex flex-column justify-content-center align-items-start px-8">
      <nav aria-label="breadcrumb" class="breadcrumb-wrap">
        <h3>{{ $t('Products') }}</h3>
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/">{{ $t('Home') }}</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link to="/products">{{ $t('Products') }}</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link :to="'/products/' + $route.params.id">{{ $route.params.id }}</router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ $route.params.name }}
          </li>
        </ol>
      </nav>
      <v-img class="image" src="@/assets/images/tebaba/bread.png" max-width="500px"></v-img>
    </div>
    <div class="content my-12">
      <div class="container">
        <v-row align="center" justify="center">
          <v-col cols="12" class="banner_wrapper">
            <h2>{{ $t('Video') }}</h2>
          </v-col>
          <v-col cols="12" class="text-center">
            <p class="mt-6">A computational fluid dynamics (CFD) study was performed to characterize the performance of
              a
              new respiratory shield. The study was intended to assess the ability of the respiratory
              shield in removing micron-sized water droplets. The suction, located at the base of the
              respiratory shield, creates an inwards flow of air towards the user which removes over 93%
              of 0.5 micron-sized particles exhaled. The remaining particles are suspended within the
              shield or re-inhaled by the user. The study also showed that when the user is given inhaled
              medications the intended level of medication is still delivered to the user from the
              nebulizer and ventilation system with this innovative air containment system.</p>
          </v-col>
        </v-row>
        <v-row align="center" justify="end" class="product">
          <v-col cols="12" lg="6" md="6" sm="12">
            <h3>{{ $t('Headings.product.heading') }}</h3>
            <ul>
              <li>1-{{ $t('Headings.product.list1') }}</li>
              <li>2-{{ $t('Headings.product.list2') }}</li>
              <li>3-{{ $t('Headings.product.list3') }}</li>
              <li>4-{{ $t('Headings.product.list4') }}</li>
              <li>5-{{ $t('Headings.product.list5') }}</li>
              <li>6-{{ $t('Headings.product.list6') }}</li>
              <li>7-{{ $t('Headings.product.list7') }}</li>
              <li>8-{{ $t('Headings.product.list8') }}</li>
            </ul>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="12">
            <v-img src="@/assets/images/tebaba/desc.png"></v-img>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      icons: {
        download: 'fal fa-download',
      },
      products: JSON.parse(sessionStorage.getItem('products')),
    }
  },
}
</script>

<style lang="scss" scoped>
.products_wrapper {

  .content {
    .product {
      h3 {
        color: #30b5ec;
      }

      ul {
        li {
          padding: 5px 0;
          color: #707070;
        }
      }
    }
  }
}
</style>
