<template>
  <div class="contact">
    <div class="back-top">
      <div class="bread-item d-flex flex-column justify-content-center align-items-start px-8">
        <nav aria-label="breadcrumb" class="breadcrumb-wrap">
          <h3>{{ $t('Products') }}</h3>
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <router-link to="/">{{ $t('Home') }}</router-link>
            </li>
            <li class="breadcrumb-item">
              <router-link to="/products">{{ $t('Products') }}</router-link>
            </li>
            <li class="breadcrumb-item">
              
              <router-link to="/products/bedfont">bedfont</router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Gastrolyzer
            </li>
          </ol>
        </nav>
        <v-img class="image" src="@/assets/images/tebaba/bread.png" max-width="500px"></v-img>
      </div>
    </div>
    <v-container class="form-wrapper">
      <v-row align="center" justify="center" class="text-center">
        <v-col cols="12">
          <h3>Gastro+™ Gastrolyzer®</h3>
          <h6>{{ $t('ProductsDetails.gastro.head1') }}</h6>
          <p>
            {{ $t('ProductsDetails.gastro.par1') }}
          </p>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="12" lg="6" md="6" sm="12">
          <v-img src="@/assets/images/tebaba/gastro.png" max-width="550px"></v-img>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="12">
          <h4>{{ $t('ProductsDetails.gastro.features') }}</h4>
          <ul class="mt-8">
            <li>{{ $t('ProductsDetails.gastro.f1') }}</li>
            <li>{{ $t('ProductsDetails.gastro.f2') }}</li>
            <li>{{ $t('ProductsDetails.gastro.f3') }}</li>
            <li>{{ $t('ProductsDetails.gastro.f4') }}</li>
            <li>{{ $t('ProductsDetails.gastro.f5') }}</li>
            <li>{{ $t('ProductsDetails.gastro.f6') }}</li>
            <li>{{ $t('ProductsDetails.gastro.f7') }}</li>
            <li>{{ $t('ProductsDetails.gastro.f8') }}</li>
            <li>{{ $t('ProductsDetails.gastro.f9') }}</li>
          </ul>
          <v-btn color="#30B5EC" class="mt-12">{{ $t('Btns.download') }}</v-btn>
        </v-col>
      </v-row>
      <div class="table-wrap">
        <h3 class="text-center mb-6">{{ $t('ProductsDetails.gastro.ts') }}</h3>
        <table>
          <tbody>
            <tr>
              <td>{{ $t('ProductsDetails.gastro.ftable.concentration_range') }}</td>
              <td>0-500ppm</td>
            </tr>
            <tr>
              <td>{{ $t('ProductsDetails.gastro.ftable.display') }}</td>
              <td>Full colour touchscreen</td>
            </tr>
            <tr>
              <td>{{ $t('ProductsDetails.gastro.ftable.detection_priciple') }}</td>
              <td>Electrochemical sensor</td>
            </tr>
            <tr>
              <td>{{ $t('ProductsDetails.gastro.ftable.repeat') }}</td>
              <td>&lt;5%</td>
            </tr>
            <tr>
              <td>{{ $t('ProductsDetails.gastro.ftable.accuracy') }}</td>
              <td>≤±3ppm/10% - whichever is greater*</td>
            </tr>
            <tr>
              <td>{{ $t('ProductsDetails.gastro.ftable.power') }}</td>
              <td>
                3 x AA (LR6 or equivalet) - up to 1000 minutes <br />
                1 x CR2032 Lithium coil cell
              </td>
            </tr>
            <tr>
              <td>T<sub>90</sub> {{ $t('ProductsDetails.gastro.ftable.res_time') }}</td>
              <td>&lt;40 seconds</td>
            </tr>
            <tr>
              <td>{{ $t('ProductsDetails.gastro.ftable.oper_temperature') }}</td>
              <td>15-35⁰C</td>
            </tr>
            <tr>
              <td>{{ $t('ProductsDetails.gastro.ftable.transport_temperature') }}</td>
              <td>0-50⁰C</td>
            </tr>
            <tr>
              <td>{{ $t('ProductsDetails.gastro.ftable.transport_pressure') }}</td>
              <td>Atmospheric ±10%</td>
            </tr>
            <tr>
              <td>{{ $t('ProductsDetails.gastro.ftable.oper_humidity') }}</td>
              <td>15-90% non-condensing</td>
            </tr>
            <tr>
              <td>{{ $t('ProductsDetails.gastro.ftable.storage_humidity') }}</td>
              <td>0-95%</td>
            </tr>
            <tr>
              <td>{{ $t('ProductsDetails.gastro.ftable.sensor_oper') }}</td>
              <td>2 years</td>
            </tr>
            <tr>
              <td>{{ $t('ProductsDetails.gastro.ftable.sensor_sens') }}</td>
              <td>1ppm</td>
            </tr>
            <tr>
              <td>{{ $t('ProductsDetails.gastro.ftable.sensor_drift') }}</td>
              <td>&lt;2% per month</td>
            </tr>
            <tr>
              <td>{{ $t('ProductsDetails.gastro.ftable.dimensions') }}</td>
              <td>Approx. 37 x 77 x 140mm</td>
            </tr>
            <tr>
              <td>{{ $t('ProductsDetails.gastro.ftable.weight') }}</td>
              <td>Approx. 215g (including batteries)</td>
            </tr>
            <tr>
              <td>{{ $t('ProductsDetails.gastro.ftable.materials') }}</td>
              <td>
                Case: polycarbonate/ABS blend <br />
                SteriTouch ® anti-microbial additive<br />
                D-piece™: polypropylene <br />
                SteriBreath™ Eco: paper <br />
                OneBreath™: polypropylene
              </td>
            </tr>
            <tr>
              <td>{{ $t('ProductsDetails.gastro.ftable.co_cross') }}</td>
              <td>&lt;4%</td>
            </tr>
          </tbody>
        </table>
      </div>
      <v-row align="center" justify="center" class="mt-16">
        <v-col cols="12" lg="6" md="6" sm="12">
          <h4>{{ $t('ProductsDetails.gastro.features') }}</h4>
          <ul class="mt-8">
            <li>{{ $t('ProductsDetails.gastro.fs1') }}</li>
            <li>{{ $t('ProductsDetails.gastro.fs2') }}</li>
            <li>{{ $t('ProductsDetails.gastro.fs3') }}</li>
            <li>{{ $t('ProductsDetails.gastro.fs5') }}</li>
            <li>{{ $t('ProductsDetails.gastro.fs6') }}</li>
            <li>{{ $t('ProductsDetails.gastro.fs7') }}</li>
            <li>{{ $t('ProductsDetails.gastro.fs8') }}</li>
            <li>{{ $t('ProductsDetails.gastro.fs9') }}</li>
            <li>{{ $t('ProductsDetails.gastro.fs10') }}</li>
          </ul>
          <v-btn color="#30B5EC" class="mt-12">{{ $t('Btns.download') }}</v-btn>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="12">
          <v-img src="@/assets/images/tebaba/gastro2.png" max-width="550px"></v-img>
        </v-col>
      </v-row>
      <div class="table-wrap">
        <h3 class="text-center mb-6">{{ $t('ProductsDetails.gastro.ts') }}</h3>
        <table>
          <tbody>
            <tr>
              <th colspan="2" rowspan="4" scope="row">
                {{ $t('ProductsDetails.gastro.stable.concentration_range') }}
              </th>
            </tr>
            <tr>
              <th colspan="1" rowspan="1" scope="row">CH<sub>4</sub></th>
              <td>0-200ppm</td>
            </tr>
            <tr>
              <th colspan="1" scope="row">H<sub>2</sub></th>
              <td>0-200ppm</td>
            </tr>
            <tr>
              <th colspan="1" scope="row">O<sub>2</sub></th>
              <td>14-23%</td>
            </tr>
            <tr>
              <th colspan="3" scope="row">{{ $t('ProductsDetails.gastro.stable.power') }}</th>
              <td>230V/100V, 50Hz-60Hz, 0.5-1.0 A</td>
            </tr>

            <tr>
              <th colspan="3" scope="row">{{ $t('ProductsDetails.gastro.stable.fuse') }}</th>
              <td>
                T 3.15 AH
              </td>
            </tr>
            <tr>
              <th colspan="3" scope="row">{{ $t('ProductsDetails.gastro.stable.startup') }}</th>

              <td>≤2 minutes</td>
            </tr>

            <tr>
              <th colspan="3" scope="row">{{ $t('ProductsDetails.gastro.stable.cali_f') }}</th>
              <td>Every 4 weeks</td>
            </tr>
            <tr>
              <th colspan="3" scope="row">{{ $t('ProductsDetails.gastro.stable.display') }}</th>
              <td>Full-colour touchscreen</td>
            </tr>
            <tr>
              <th colspan="3" scope="row">{{ $t('ProductsDetails.gastro.stable.detection') }}</th>
              <td>Electrochemical sensor (O<sub>2</sub>& H<sub>2</sub>) <br />Laser (CH<sub>4</sub>)</td>
            </tr>
            <tr>
              <th colspan="1" rowspan="13" scope="row">
                {{ $t('ProductsDetails.gastro.stable.acc') }}
              </th>
            </tr>
            <tr>
              <th colspan="1" rowspan="4" scope="row">CH<sub>4</sub></th>
            </tr>
            <tr>
              <th colspan="1" rowspan="1" scope="row">{{ $t('ProductsDetails.gastro.stable.resolution') }}</th>
              <td colspan="3">1ppm</td>
            </tr>
            <tr>
              <th colspan="1" rowspan="1" scope="row">{{ $t('ProductsDetails.gastro.stable.acc') }}</th>
              <td colspan="3">±10% of reading </td>
            </tr>
            <tr>
              <th colspan="1" rowspan="1" scope="row">{{ $t('ProductsDetails.gastro.stable.repeat') }}</th>
              <td colspan="3">&lt;5% difference on consecutive readings</td>
            </tr>
            <tr>
              <th colspan="1" rowspan="4" scope="row">H<sub>2</sub></th>
            </tr>
            <tr>
              <th colspan="1" rowspan="1" scope="row">{{ $t('ProductsDetails.gastro.stable.resolution') }}</th>
              <td colspan="3">1ppm</td>
            </tr>
            <tr>
              <th colspan="1" rowspan="1" scope="row">{{ $t('ProductsDetails.gastro.stable.acc') }}</th>
              <td colspan="3">±10% of reading </td>
            </tr>
            <tr>
              <th colspan="1" rowspan="1" scope="row">{{ $t('ProductsDetails.gastro.stable.repeat') }}</th>
              <td colspan="3">&lt;5% difference on consecutive readings</td>
            </tr>
            <tr>
              <th colspan="1" rowspan="4" scope="row">O<sub>2</sub></th>
            </tr>
            <tr>
              <th colspan="1" rowspan="1" scope="row">{{ $t('ProductsDetails.gastro.stable.resolution') }}</th>
              <td colspan="3">1ppm</td>
            </tr>
            <tr>
              <th colspan="1" rowspan="1" scope="row">{{ $t('ProductsDetails.gastro.stable.acc') }}</th>
              <td colspan="3">±10% of reading </td>
            </tr>
            <tr>
              <th colspan="1" rowspan="1" scope="row">{{ $t('ProductsDetails.gastro.stable.repeat') }}</th>
              <td colspan="3">&lt;5% difference on consecutive readings</td>
            </tr>
            <tr>
              <th colspan="3" scope="row">{{ $t('ProductsDetails.gastro.stable.carbon') }}
                (H <sub>2</sub> only)</th>
              <td>&lt;4%</td>
            </tr>
            <tr>
              <th colspan="2" rowspan="3" scope="row">
                {{ $t('ProductsDetails.gastro.stable.temp_range') }}
              </th>
            </tr>
            <tr>
              <th colspan="1" rowspan="1" scope="row">{{ $t('ProductsDetails.gastro.stable.operating') }}</th>
              <td>15-35<sup>o</sup> C(59<sup>o</sup>-95<sup>o</sup>F) </td>
            </tr>
            <tr>
              <th colspan="1" scope="row">{{ $t('ProductsDetails.gastro.stable.storage') }}</th>
              <td>0-40<sup>o</sup> C(32<sup>o</sup>-104<sup>o</sup>F)</td>
            </tr>
            <tr>
              <th colspan="2" rowspan="3" scope="row">
                {{ $t('ProductsDetails.gastro.stable.pressure') }}
              </th>
            </tr>
            <tr>
              <th colspan="1" rowspan="1" scope="row">{{ $t('ProductsDetails.gastro.stable.operating') }}</th>
              <td>912-1114mbar (Atmospheric ±10%)</td>
            </tr>
            <tr>
              <th colspan="1" scope="row">{{ $t('ProductsDetails.gastro.stable.storage') }}</th>
              <td>912-1114mbar (Atmospheric ±10%)</td>
            </tr>

            <tr>
              <th colspan="2" rowspan="3" scope="row">
                {{ $t('ProductsDetails.gastro.stable.humidity') }}
              </th>
            </tr>
            <tr>
              <th colspan="1" rowspan="1" scope="row">{{ $t('ProductsDetails.gastro.stable.operating') }}</th>
              <td>30-75% RH (non-condensing)</td>
            </tr>
            <tr>
              <th colspan="1" scope="row">{{ $t('ProductsDetails.gastro.stable.storage') }}</th>
              <td>15-90% RH (non-condensing)</td>
            </tr>

            <tr>
              <th colspan="2" rowspan="4" scope="row">
                {{ $t('ProductsDetails.gastro.stable.sensor_oper') }}
              </th>
            </tr>
            <tr>
              <th colspan="1" rowspan="1" scope="row">CH<sub>4</sub></th>
              <td>5 years</td>
            </tr>
            <tr>
              <th colspan="1" scope="row">H<sub>2</sub></th>
              <td>5 years</td>
            </tr>
            <tr>
              <th colspan="1" scope="row">O<sub>2</sub></th>
              <td>2 years</td>
            </tr>
            <tr>
              <th colspan="3" scope="row">{{ $t('ProductsDetails.gastro.stable.dimensions') }}</th>
              <td>Approx. 474 x 310 x 135 mm</td>
            </tr>
            <tr>
              <th colspan="3" scope="row">{{ $t('ProductsDetails.gastro.stable.weight') }}</th>
              <td>Approx. 8.5kg</td>
            </tr>
            <tr>
              <th colspan="3" scope="row">{{ $t('ProductsDetails.gastro.stable.monitor') }}</th>
              <td>Case: aluminium</td>
            </tr>
            <tr>
              <th colspan="3" scope="row">{{ $t('ProductsDetails.gastro.stable.classification') }}</th>
              <td>
                Class IME equipment: (externally powered) <br />
                Type BF applied part <br />
                Method of sterilization (not suitable for sterilization) <br />
                Not suitable for use in an oxygen-rich environment <br />
                Intended for continuous use
              </td>
            </tr>
            <tr>
              <th colspan="3" scope="row">{{ $t('ProductsDetails.gastro.stable.res_time') }}</th>
              <td>≤45 seconds</td>
            </tr>
          </tbody>
        </table>
      </div>
      <SendMessage class="mt-16"></SendMessage>
      <Subs class="mt-12"></Subs>
    </v-container>
  </div>
</template>

<script>
import Subs from '@/components/Subs.vue'
import SendMessage from '@/components/sendMessage.vue';

export default {
  components: { Subs, SendMessage },
  data() {
    return {
      icons: {
        arrow: 'fal fa-long-arrow-right',
        mail: 'fas fa-envelope',
        phone: 'fas fa-mobile',
        address: 'fas fa-map-marker-alt',
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.form-wrapper {
  margin-top: 70px;

  h3,
  h6,
  h4 {
    color: #e4007e;
  }

  .table-wrap {
    margin-top: 100px;

    table {
      width: 80%;
      margin: auto;

      tbody {
        tr {

          td,
          th {
            padding: 10px;
            border: 1px solid #000;
            font-weight: bold;

            &:first-child {
              color: #fff;
              background: #e4007e;
            }
          }
        }
      }
    }
  }
}
</style>
