<template>
    <div class="products_wrapper">
        <div class="bread-item d-flex flex-column justify-content-center align-items-start px-8">
            <nav aria-label="breadcrumb" class="breadcrumb-wrap">
                <h3>{{ $t('Products') }}</h3>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <router-link to="/">{{ $t('Home') }}</router-link>
                    </li>
                    <li class="breadcrumb-item">
                        <router-link to="/products">{{ $t('Products') }}</router-link>
                    </li>
                    <li class="breadcrumb-item">
                        <router-link to="/products">{{ $route.params.id }}</router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        safer
                    </li>
                </ol>
            </nav>
            <v-img class="image" :src="require('@/assets/images/tebaba/bread.png')" max-width="500px"></v-img>
        </div>
        <div class="content my-12">
            <div class="container">
                <v-row align="center" justify="center">
                    <v-col cols="12" class="banner_wrapper">
                        <h2>{{ $t('Video') }}</h2>
                    </v-col>
                    <v-col cols="12" class="text-center">
                        <p class="mt-6">{{ $t('ProductsDetails.safer.par1') }}</p>
                    </v-col>
                </v-row>
                <v-row align="center" justify="end" class="product">
                    <v-col cols="12" lg="6" md="6" sm="12">
                        <h3>{{ $t('Headings.product.heading') }}</h3>
                        <ul>
                            <li>1-{{ $t('Headings.product.list1') }}</li>
                            <li>2-{{ $t('Headings.product.list2') }}</li>
                            <li>3-{{ $t('Headings.product.list3') }}</li>
                            <li>4-{{ $t('Headings.product.list4') }}</li>
                            <li>5-{{ $t('Headings.product.list5') }}</li>
                            <li>6-{{ $t('Headings.product.list6') }}</li>
                            <li>7-{{ $t('Headings.product.list7') }}</li>
                            <li>8-{{ $t('Headings.product.list8') }}</li>
                        </ul>
                    </v-col>
                    <v-col cols="12" lg="6" md="6" sm="12">
                        <v-img :src="require('@/assets/images/tebaba/desc.png')"></v-img>
                    </v-col>
                </v-row>
                <v-row align="center" justify="center">
                    <v-col cols="8" lg="4" md="4" sm="4">
                        <v-img :src="require('@/assets/images/tebaba/hpe.png')" class="mx-auto" max-width="200px"></v-img>
                    </v-col>
                    <v-col cols="8" lg="4" md="4" sm="4">
                        <v-img :src="require('@/assets/images/tebaba/ems.png')" class="mx-auto" max-width="200px">
                        </v-img>
                    </v-col>
                    <v-col cols="8" lg="4" md="4" sm="4">
                        <v-img :src="require('@/assets/images/tebaba/ida.png')" class="mx-auto" max-width="200px">
                        </v-img>
                    </v-col>
                </v-row>
                <SendMessage class="mt-16"></SendMessage>
            </div>
        </div>
    </div>
</template>
<script>
import SendMessage from '@/components/sendMessage.vue';

export default {
    data() {
        return {
            icons: {
                download: "fal fa-download",
            },
            products: JSON.parse(sessionStorage.getItem("products")),
        };
    },
    components: { SendMessage }
}
</script>
  
<style lang="scss" scoped>
.products_wrapper {

    .content {
        .product {
            h3 {
                color: #30b5ec;
            }

            ul {
                li {
                    padding: 5px 0;
                    color: #707070;
                }
            }
        }

        p {
            color: #30b5ec;
        }
    }

    .banner_wrapper {
        width: 100%;
        height: 300px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #a6dd49;
    }
}
</style>
  