<template>
    <div class="products_wrapper">
        <div class="bread-item d-flex flex-column justify-content-center align-items-start px-8">
            <nav aria-label="breadcrumb" class="breadcrumb-wrap">
                <h3>{{ $t('Products') }}</h3>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <router-link to="/">{{ $t('Home') }}</router-link>
                    </li>
                    <li class="breadcrumb-item">
                        <router-link to="/products">{{ $t('Products') }}</router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        safer
                    </li>
                </ol>
            </nav>
            <v-img class="image" src="@/assets/images/tebaba/bread.png" max-width="500px"></v-img>
        </div>
        <div class="content my-12">
            <div class="container">
                <v-row align="center" justify="start">
                    <v-col cols="12" lg="3" md="4" sm="6" v-for="product in products" :key="product.id">
                        <div class="product d-flex flex-column align-center justify-center">
                            <v-img :src="require('@/assets/images/tebaba/' + product.src)" contain max-width="200px"
                                max-height="200px" />
                            <h6 class="mt-4" @click="openDetails(product)" v-if="product.details !== false">
                                {{ product.name }}
                            </h6>
                            <h6 class="mt-4" v-else>
                                {{ product.name }}
                            </h6>

                            <v-btn color="#30B5EC" class="mt-2 btn-down" v-if="product.download !== false">{{
                            $t('Btns.download') }} <v-icon small class="mx-2">{{ icons.download }}</v-icon>
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            icons: {
                download: 'fal fa-download',
            },
            products: [
                {
                    id: 'Safer',
                    name: 'Safer',
                    src: 'desc.png',
                    download: false,
                    details: true,
                },
            ],
        }
    },
    methods: {
        openDetails(product) {
            this.$router.push(`/products/${product.name}/safer`)
            // sessionStorage.setItem("product", JSON.stringify(product));
        },
    },
}
</script>
  
<style lang="scss" scoped>
.products_wrapper {

    .content {
        .product {
            min-height: 350px;
            padding: 10px 0;
            border: 2px solid #30b5ec;
            border-radius: 10px;

            h6 {
                cursor: pointer;
                color: #30b5ec;
            }

            .btn-down {
                width: 90%;
                margin: 0 auto;
            }
        }

        p {
            color: #30b5ec;
        }
    }

    .banner_wrapper {
        width: 100%;
        color: #fff;
        text-align: center;
        height: 300px;
        background-color: #a6dd49;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>
  